import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => (
  <header className="sticky top-0 bg-white ">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <StaticImage
          src="../../images/logo.png"
          alt="VETLIGHT® BY TENDLITE®"
          height="60"
          layout="fixed"
          placeholder="tracedSVG"
        />
        <span className="logo-text">Vetlight®</span>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4 top-link" href="#features">
          Features
        </AnchorLink>

        <AnchorLink className="px-4 top-link" href="#reviews">
          Reviews
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
